.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
width: 100%;
height: 100%;
flex-direction: column;
flex-grow: 1;
align-items: center;
justify-content: center;
padding: 32px;
}