.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
width: 250px;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 32px;
background-color: rgb(0 0 0 / 75%);
border-radius: 8px;
}