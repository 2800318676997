@use 'sass:color';

$button-color-base: #e1e1e1;
$button-color-base-hover: color.scale($button-color-base, $lightness: 15%);
$button-color-base-disabled: color.scale(
  $button-color-base,
  $lightness: 50%,
  $saturation: -50%
);
$button-color-primary: #017ab4;
$button-color-primary-hover: color.scale(
  $button-color-primary,
  $lightness: 15%
);
$button-color-primary-disabled: color.scale(
  $button-color-primary,
  $lightness: 50%,
  $saturation: -50%
);

.ant-popover,
.ant-btn {
  border-radius: 5px !important;
}

.ant-btn {
  border-color: $button-color-base !important;
  background-color: $button-color-base !important;
  color: #222 !important;
  outline: none !important;
  text-align: center !important;
  transition-duration: 0.25s !important;

  &:disabled {
    border-color: $button-color-base-disabled !important;
    background-color: $button-color-base-disabled !important;
    cursor: not-allowed !important;
  }

  &:not([disabled]):hover,
  &:not([disabled]):active {
    border-color: $button-color-base-hover !important;
    background-color: $button-color-base-hover !important;
  }

  &:not([disabled]):focus {
    box-shadow: 0 0 2px 2px #1e90ff !important;
  }
}

.ant-btn-primary {
  border-color: $button-color-primary !important;
  background-color: $button-color-primary !important;
  color: #fff !important;

  &:not([disabled]):hover,
  &:not([disabled]):active {
    border-color: $button-color-primary-hover !important;
    background-color: $button-color-primary-hover !important;
  }
}

.ant-popover-arrow {
  border-color: #fafafa !important;
}

.ant-popover-inner {
  background-color: #fafafa !important;
}
