.cls1 { /*!*/ }
.cls2 {
composes: cls1;

composes: cls2 from "./styleHelpers-borderRadius.module.scss";
composes: cls2 from "./styleHelpers-fontSizeInput.module.scss";
composes: cls2 from "./styleHelpers-lineHeightInput.module.scss";
composes: cls2 from "./styleHelpers-borderInput.module.scss";
composes: cls2 from "./styleHelpers-backgroundColorInput.module.scss";

display: block;
width: 100%;
padding: 0 8px;
appearance: none;
outline: none;
}