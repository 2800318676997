.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
display: grid;
place-items: center;
background-color: #fff;
z-index: +1;
}